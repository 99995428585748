import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { Accordion, PropertyGroup } from '../../components';

import css from './ListingPage.module.css';

const SectionExtraInfoMaybe = props => {
  const { publicData } = props;
  if (!publicData) {
    return null;
  }

  const { addOns, faqs } = publicData;

  return (
    <div className={css.sectionFeatures}>
      {addOns?.length ? (
        <div className={css.sectionFeatures}>
          <h2 className={css.featuresTitle}>
            <FormattedMessage id={`ListingPage.addOnsTitle`} tagName=""/>
          </h2>
          {addOns.map(option => (
            <p key={option} className={css.listItem}>
              <span className={css.separator}>•</span>
              {option}
            </p>
          ))}
        </div>
      ) : null}

      {faqs?.length ? (
        <div className={css.sectionFeatures}>
          <h2 className={css.featuresTitle}>
            <FormattedMessage id={`ListingPage.faqsTitle`} tagName=""/>
          </h2>

          {faqs.map(question => (
            <Accordion key={question.question} className={css.faq} title={question.question}>
              {question.answer}
            </Accordion>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default SectionExtraInfoMaybe;
