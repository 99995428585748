import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { ALL_CITIES, searchLocation } from '../../util/locationHelpers';

import css from './ListingPage.module.css';

const CitiesList = props => {
  const { cities, title } = props;

  return cities?.length ? (
    <div className={css.sectionCities}>
      <h3>{title}</h3>
      <div className={css.twoColumns}>
        {cities.map(city => (
          <p key={city} className={css.listItem}>
            <span className={css.separator}>•</span>
            <h3 className={css.itemContent}>{searchLocation(ALL_CITIES, city).label}</h3>
          </p>
        ))}
      </div>
    </div>
  ) : null;
};

const SectionCitiesMaybe = props => {
  const { publicData } = props;
  if (!publicData) {
    return null;
  }

  const { standardWorkCities, extraWorkCities } = publicData || {};

  const standardCitiesTitle = <FormattedMessage id="ListingPage.standardCitiesTitle" />;
  const extraCitiesTitle = <FormattedMessage id="ListingPage.extraCitiesTitle" />;

  return (
    <div className={css.sectionFeatures}>
      <h2 className={css.featuresTitle}>
        <FormattedMessage id="ListingPage.citiesTitle" tagName=""/>
      </h2>

      <CitiesList cities={standardWorkCities} title={standardCitiesTitle} />
      <CitiesList cities={extraWorkCities} title={extraCitiesTitle} />
    </div>
  );
};

export default SectionCitiesMaybe;
