import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { InlineTextButton } from '../../components';

import css from './ListingPage.module.css';

const SectionHeading = props => {
  const {
    priceTitle,
    formattedPrice,
    richTitle,
    baseLocation,
    hostLink,
  } = props;

  return (
    <div className={css.sectionHeading}>
      {formattedPrice && (
        <div className={css.desktopPriceContainer}>
          <FormattedMessage id="ListingPage.priceIndicator"/>
          <div className={css.desktopPriceValue} title={priceTitle}>
            {formattedPrice}
          </div>
        </div>
      )}
      <div className={css.heading}>
        <h1 className={css.title}>{richTitle}</h1>
        <div className={css.author}>
          {baseLocation}
          <FormattedMessage id="ListingPage.hostedBy" values={{ name: hostLink }} />
        </div>
      </div>
    </div>
  );
};

export default SectionHeading;
